<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">支付页面</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
      <!-- <div class="nav_right_btn">下一页</div> -->
    </div>
    <div class="content">
      <!-- <div>
        <img :src="finish" alt="" width="100" height="100" class="finish-img">
      </div>
      <div class="finish-text">支付成功!</div>-->
      <div class="time">
        <span class="back-his">页面将在</span>
        <span class="time-detail">{{ time }}</span>
        <span class="back-his">
          s内跳转，
          <span class="to-his" @click="toBack">返回首页</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import finish from "@/assets/finish.jpg";
// import { getToken } from '@/api/api'

export default {
  name: "Notify",
  data() {
    return {
      cancel,
      finish,
      time: 3,
    };
  },
  mounted() {
    this.countDown();
  },
  methods: {
    countDown() {
      const UrlArr = window.location.href.split("notify");
      const interVal = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(interVal);
          // this.$router.push({ name: 'Home' })
          this.$router.push("/" + UrlArr[1]);
        }
      }, 1000);
    },

    // 点击返回首页
    toBack() {
      // console.log(window.location.href)
      // console.log(window.location.href.lastIndexOf('/'))
      // console.log(window.location.href.split('notify'))
      const UrlArr = window.location.href.split("notify");
      // console.log(UrlArr[1])
      // console.log(typeof (UrlArr[1]))
      // this.$router.push({ name: 'Home' })
      // this.$router.push('/home' + '/?phone=15526550876')
      this.$router.push("/" + UrlArr[1]);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .finish-img {
      border-radius: 100%;
    }

    & > .finish-text {
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 25px;
    }

    & > .time {
      margin-top: 1rem;
      display: flex;
      align-items: center;

      & > .time-detail {
        color: #d00000;
        font-size: 1.5rem;
        margin: 0 0.2rem;
      }

      .to-his {
        color: #4b95cb;
      }
    }
  }
}

.nav_left_btn_click {
  opacity: 0.5;
}
.nav_right_btn_click {
  opacity: 0.5;
}
</style>
